import React from 'react';

function removeImageAndVideoInMarkdown(blog) {
  const contentfulMarkdownRegexForImageAndVideo = /(?:!\[(.*?)\]\((.*?)\))|(`(.*)`)/g;

  return blog.childMarkdownRemark.rawMarkdownBody.replace(contentfulMarkdownRegexForImageAndVideo, '').substring(0, 100)
}

export default function SingleNewsletter(props) {
  const { previewPhoto, title, blog, author, createdAt, dateCreated, pdfLink } = props;

  return (
    <div className="col-md-4 mb-5">
      <div className="newsletter-link">
        <div className="card blog-card">
          <img src={previewPhoto} alt="" />
          <div className="p-5">
            <h4>{title}</h4>
            <p className="gray mb-5">
              {removeImageAndVideoInMarkdown(blog)}
              ...
            </p>
            <div className="d-flex justify-content-between mta">
              <span className="purple">{author.name}</span>
              <span className="dark-gray">
                {dateCreated || new Date(createdAt).toDateString()}
              </span>
            </div>
            <a className="newsletter-view-btn" href={pdfLink} target="_blank">
              View PDF
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
