import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import HelmetComponent from '../components/helmetcomponent';
import defaultImg from '../img/defaultImg.jpg';
import SingleNewsletter from './single-newsletter';

function Blogs({ blogPosts }) {
  return blogPosts?.map((post) => {
    const { blogPreviewPhoto, title, blog, author, createdAt, dateCreated, pdfLink } = post;

    let previewPhoto = defaultImg;

    if (blogPreviewPhoto) {
      previewPhoto = blogPreviewPhoto.file.url
    }

    return (
      <SingleNewsletter
        author={author}
        blog={blog}
        createdAt={createdAt}
        dateCreated={dateCreated}
        pdfLink={pdfLink}
        previewPhoto={previewPhoto}
        title={title}
      />
    )
  })
}

export default function Template({ data }) {
  const blogPostsToShow = 6;
  const [showBlogPosts, setShowBlogPosts] = useState(blogPostsToShow);

  const onLoadMore = e => {
    e.preventDefault();
    setShowBlogPosts(showBlogPosts + blogPostsToShow);
  };

  const { title, blogPosts } = data.allContentfulPageNewsletters.edges[0].node;
  const showLoadMore = blogPosts?.length > showBlogPosts;
  
  return (
    <Layout nav="colored-nav">
      <HelmetComponent title={title}/>
      <div>
        <section id="section-blog">
          <div id="page-sub-header" className="white-space">
            <div className="container">
              <div className="page-sub-header-content">
                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <h1 className="black">{title}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      
        <section id="articles" className="mt-5">
          <div className="container">
            <div className="row blog-box">
              <Blogs blogPosts={blogPosts}/>
            </div>
          </div>
          {showLoadMore && (
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div id="loadMore">
                    <a href="#" onClick={onLoadMore}>
                      Load More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query NewslettersByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        date(formatString: "MMMM DD, YYYY")
        title
        description
        attachments {
          filename
        }
        related {
          post
        }
      }
    }
    
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            title
            path
          }
        }
      }
    }

    allContentfulPageNewsletters {
      edges {
        node {
          title
          blogPosts {
            dateCreated(formatString: "MMMM DD, YYYY")
            slug
            author {
              name
            }
            createdAt
            title
            blog {
              blog
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
            blogPreviewPhoto {
              file {
                url
              }
            }
            pdfLink
          }
        }
      }
    }
  }
`;
